<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Perjalanan Dinas Dalam Provinsi </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputDalamprovinsi">
            <CRow>
              <CCol sm="12">
                <CTextarea
                  v-model="uraian_kegiatan"
                  label="Uraian Kegiatan"
                  placeholder="Input Uraian Kegiatan"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_berangkat"
                  type="date"
                  label="Tanggal Pergi"
                  placeholder="Input Tanggal Pergi"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_pulang"
                  type="date"
                  label="Tanggal Pulang"
                  placeholder="Input Tanggal Pulang"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="tujuan"
                  label="Tujuan"
                  placeholder="Input Tujuan"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="uang_harian"
                  type="number"
                  label="Uang Harian"
                  placeholder="Input Uang Harian"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="uang_transport"
                  type="number"
                  label="Uang Transport"
                  placeholder="Input Uang Transport"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="uang_hotel"
                  type="number"
                  label="Uang Hotel"
                  placeholder="Input Uang Hotel"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="total_uang"
                  type="number"
                  label="Total"
                  placeholder="Input Total"
                  readonly
                />
              </CCol>   
              <CCol sm="6">             
                <div class="form-group">
                <label>Peserta</label>
                <vue-bootstrap-typeahead
                  v-model="id_peserta"
                  :data="pesertas"
                />
                </div>
              </CCol>           
            </CRow>          
              <div class="form-actions float-right">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/perjadin/dalamprovinsi">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import InputTag from 'vue-input-tag'
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: { 
    InputTag ,
  },
  data () {
    return {
      uraian_kegiatan : "",
      tgl_berangkat : "",
      tgl_pulang : "",
      tujuan : "",
      id_peserta : "",
      uang_harian : 0,
      uang_transport : 0,
      uang_hotel : 0,
      total_uang : 0,
      // placeholder : "Pilih File",
      selected: [], // Must be an array reference!
      pesertas: [],
      show: true,
    }
  },
  mounted(){
  this.loadData();
  },
  
  methods: {

    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"pegawais")
        .then((response) => {
              // this.pesertas=response.data;
              const datas = response.data;
              
              this.pesertas = datas.map(d=>{return d['nama_pegawai']});;
              console.log("datas::"+this.pesertas)
        });
    },

    inputDalamprovinsi: function(event){

      const formData = new FormData();
        
        formData.append('uraian_kegiatan', this.uraian_kegiatan)
        formData.append('tgl_berangkat', this.tgl_berangkat)
        formData.append('tgl_pulang', this.tgl_pulang)
        formData.append('tujuan', this.tujuan)
        formData.append('id_peserta', this.id_peserta)
        formData.append('uang_harian', this.uang_harian)
        formData.append('uang_transport', this.uang_transport)
        formData.append('uang_hotel', this.uang_hotel)
        formData.append('total_uang', this.total_uang)
        formData.append('jenis_tujuan', "1")
      
      // alert(JSON.stringify(anggota));
      axios.post(process.env.VUE_APP_BASE_URL+"perjadin", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/perjadin/dalamprovinsi');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>